.container {
    position: fixed;
    top: 0;
    right: 0;
    padding: .5em;
    text-align: right;
    font-size: 16px;
    background: black;
    opacity: .5;
    transition: opacity .3s;
}

.pending {
    opacity: 1;
}

.ONLINE {
    background: green;
}

.OFFLINE {
    background: red;
}
